<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Eligible Promotions</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-2"
          v-model="search"
          :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Claims')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn fab small class="ml-2" v-on="{ ...onMenu, ...onTooltip }">
                <v-icon>mdi-magnify-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Advanced Search") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  :items="fiscalYears"
                  v-model="filterByFiscalYear"
                  label="Fiscal Year"
                  clearable
                  @input="onSubmit"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <CountriesField
                  dense
                  v-model="filterByCountries"
                  :notAvailableCountries="['MEX']"
                  :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Countries')"
                  @input="onSubmit"
                ></CountriesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <PromotionTypesField
                  dense
                  v-model="filterByPromotionTypes"
                  :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Promotion Types')"
                  @input="onSubmit"
                ></PromotionTypesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <ClaimTypesField
                  dense
                  v-model="filterByClaimTypes"
                  :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Claim Types')"
                  @input="onSubmit"
                ></ClaimTypesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable dense v-if="isAdminOrPrincipalOwner">
              <v-list-item-content dense>
                <v-checkbox
                  dense
                  v-model="filterByClaimOnBehalf"
                  label="Only Allowed to Claim on Behalf"
                  @change="onSubmit"
                  :items="claimOnBehalfItems"
                ></v-checkbox>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-tooltip bottom v-if="hasFilters && !widget">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn fab small class="ml-2" color="secondary" v-on="{ ...onTooltip }" @click="onClearSearch">
            <v-icon>mdi-magnify-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Clear Search") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="!widget ? headers : widgetHeaders"
        :server-items-length="total"
        :options.sync="options"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
      >
        <template v-slot:item.closestSubmittableEffectiveDate="{ item }">
          {{ item.closestSubmittableEffectiveDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
        </template>
        <template v-slot:item.closestSubmittableExpirationDate="{ item }">
          {{ item.closestSubmittableExpirationDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
        </template>
        <template v-slot:item.promotion.promotionKey="{ item }">
          <a @click="showUploadedFilesDialog(item.promotion)">{{ item.promotion.promotionKey }}</a>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            :to="{ name: 'cprClaimOcr', params: { id: item.promotion.id } }"
            v-if="
              item.promotion.promotionType.claimable &&
                !item.promotion.promotionType.publiclyAvailable &&
                $privilege.hasPrivilege('CLAIM_CREATE')
            "
            class="ml-2"
            color="success"
          >
            Start a New Claim
          </v-btn>
        </template>
        <template v-slot:item.closestSubmittableSubmissionDeadlineDate="{ item }">
          {{ item.closestSubmittableSubmissionDeadlineDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
        </template>
      </v-data-table>
      <v-dialog v-model="uploadedFilesDialog" max-width="800" persistent>
        <v-card>
          <v-card-title>Files</v-card-title>
          <v-card-text>
            <v-container class="ma-0 pa-0">
              <v-row dense>
                <v-col cols="1" dense></v-col>
                <v-col cols="11" dense>
                  <ol>
                    <li v-for="(upload, i) in uploads" :key="i">
                      <a :href="upload.href" target="_blank">
                        {{ upload.originalFilename }}
                      </a>
                    </li>
                  </ol>
                </v-col>
              </v-row>
              <v-row class="text-right" dense>
                <v-col cols="12" dense>
                  <v-btn class="primary" @click="uploadedFilesDialog = false">Close</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import ClaimTypesField from "../../gapp-components/components/fields/ClaimTypesField.vue";
import PromotionTypesField from "../../gapp-components/components/fields/PromotionTypesField.vue";
import CountriesField from "../../gapp-components/components/fields/CountriesField.vue";
import { mapGetters } from "vuex";

export default {
  components: { CountriesField, PromotionTypesField, ClaimTypesField },
  name: "CprClaims",
  props: {
    widget: { type: Boolean, default: false }
  },
  metaInfo: {
    title: "Claims"
  },
  data() {
    return {
      search: "",
      filterByFiscalYear: undefined,
      filterByCountries: [],
      filterByPromotionTypes: [],
      filterByClaimTypes: [],
      filterByClaimOnBehalf: null,
      loading: false,
      items: [],
      nameFilter: "",
      headers: [
        {
          value: "actions",
          text: "",
          sortable: false,
          align: "center"
        },
        {
          value: "promotion.promotionKey",
          text: "Promotion Number",
          sortable: true,
          width: this.widget ? "20%" : ""
        },
        {
          value: "promotion.name",
          text: "Title",
          sortable: true,
          width: this.widget ? "50%" : ""
        },
        {
          value: "closestSubmittableEffectiveDate",
          text: "Starts",
          align: "center",
          width: this.widget ? "15%" : ""
        },
        {
          value: "closestSubmittableExpirationDate",
          text: "Ends",
          align: "center",
          width: this.widget ? "15%" : ""
        },
        {
          value: "closestSubmittableSubmissionDeadlineDate",
          text: "Claiming Ends",
          align: "center",
          sortable: false
        }
      ],
      total: 0,
      options: {
        sortBy: ["promotionKey"],
        sortDesc: [false],
        itemsPerPage: 10
      },

      effectiveItems: [
        { text: "Active", value: true },
        { text: "Inactive", value: false }
      ],
      claimOnBehalfItems: [
        { text: "Allowed", value: true },
        { text: "Disallowed", value: false }
      ],
      fiscalYears: [],
      uploadedFilesDialog: false,
      uploads: {}
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    onClear() {
      this.search = "";
      this.getData();
    },
    onSubmit() {
      this.options.page = 0;
      this.getData();
    },
    onClearSearch() {
      this.search = "";
      this.filterByFiscalYear = undefined;
      this.filterByCountries = [];
      this.filterByPromotionTypes = [];
      this.filterByClaimTypes = [];
      this.onSubmit();
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = {};
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }
      // Filter by fiscal year
      if (this.filterByFiscalYear) {
        filters.fiscalYear = this.filterByFiscalYear;
      }
      // Filter by countries
      if (this.filterByCountries && this.filterByCountries.length > 0) {
        filters.countries = this.filterByCountries;
      }
      // Filter by claim types
      if (this.filterByClaimTypes && this.filterByClaimTypes.length > 0) {
        filters.claimTypes = this.filterByClaimTypes.map(claimType => {
          return {
            id: claimType.id
          };
        });
      }
      // Filter by claim on behalf
      if (this.filterByClaimOnBehalf) {
        filters.allowClaimingOnBehalf = this.filterByClaimOnBehalf;
      }
      // Filter by promotion types
      if (this.filterByPromotionTypes && this.filterByPromotionTypes.length > 0) {
        filters.promotionTypes = this.filterByPromotionTypes.map(promotionType => {
          return {
            id: promotionType.id
          };
        });
      }

      this.$api
        .post(
          "/api/promotions/search/eligible?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.items = data.content.filter(
            item => item.valid == true && !this.$promotion.isPromotionContestBased(item.promotion)
          );
          this.items = this.$promotion.findAndAssignClosestDates(this.items);
          this.total = data.totalElements;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showUploadedFilesDialog(item) {
      this.$api
        .get("/api/promotions/" + item.id + "/uploads/")
        .then(({ data }) => {
          this.uploads = data._embedded.uploads;
        })
        .then(() => {
          if (this.uploads.length == 1) {
            window.open(this.uploads[0].href, "_blank");
          } else {
            this.uploadedFilesDialog = true;
          }
        });
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      { text: "Eligible Promotions" }
    ]);

    // Populate Fiscal Years
    var d = new Date();
    var year = d.getFullYear();
    this.fiscalYears.push({ value: year + 1, text: year + 1 });
    this.fiscalYears.push({ value: year, text: year });
    this.fiscalYears.push({ value: year - 1, text: year - 1 });
    this.fiscalYears.push({ value: year - 2, text: year - 2 });
    this.fiscalYears.push({ value: year - 3, text: year - 3 });
    this.fiscalYears.push({ value: year - 4, text: year - 4 });
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram", "selectedParticipant"]),
    hasFilters() {
      let check =
        (this.search && this.search.length > 0) ||
        (this.filterByCountries && this.filterByCountries.length > 0) ||
        (this.filterByPromotionTypes && this.filterByPromotionTypes.length > 0) ||
        (this.filterByClaimTypes && this.filterByClaimTypes.length > 0);
      return check;
    },
    isAdminOrPrincipalOwner() {
      return ["500", "510", "600", "610", "700", "710", "800", "810", "930"].includes(
        this.selectedParticipant.participantType.participantTypeKey
      );
    },
    widgetHeaders() {
      return this.headers.filter(x => x.value != "submissionDeadlines");
    },
    isGappInternalUser() {
      return this.$privilege.isGappInternalUser();
    }
  }
};
</script>
